@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
 /* Primng icons */
 @font-face {
  font-family: 'PrimeIcons';
  src: 
  url('/assets/iconfont/primeicons.woff') format('woff'),
  url('/assets/iconfont/primeicons.ttf') format('truetype'),
  url('/assets/iconfont/primeicons.svg?#primeicons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: optional;
}
*[hidden] { display: none; }
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(/assets/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
       url(/assets/iconfont/MaterialIcons-Regular.woff) format('woff'),
       url(/assets/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}
html,
body {
  margin: 0;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
 color: black;
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: 800;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased
  }
  
.md-header{
  margin: 0 auto;
  display: block;
  padding: 40px;
  position: relative;
  
}

.mat-button-toggle-group-mbearance-standard .mat-button-toggle+.mat-button-toggle {
  border-left: solid 0px rgba(0,0,0,.12);
}
hero{
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  display: block;
  content: "";
  top: 0!important;
  left: 0!important;
  width: 100%;
  height: 100%
}
.mat-accent .mat-mdc-slider-thumb-label-text {
  font-weight: 700;
  font-size: 25px
}
mb-login,mb-oauth-callback, .min18 {
  display: flex;           /* establish flex container */
  flex-direction: column;  /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center;     /* center items horizontally, in this case */
  height: -moz-calc(100% - (120px));
  height: -webkit-calc(100% - (120px));
  height: calc(100% - (120px ));
}
mb-login>div,mb-oauth-callback>div, min18{
text-align: center;
}
.mat-drawer-container {
   background-color:transparent!important;
  color: rgba(0,0,0,.87);
}
.mat-toolbar.mat-primary {
  background:transparent!important;
  color: #fff;
}

mb-login > div > div > button.ui-button{
  font-family: 'Pathway Gothic One',sans-serif;
  font-size: 40px
}
mb-login > div > div > button.ui-button{
  font-family: 'Pathway Gothic One',sans-serif;
  font-size: 40px
}
mb-login > div > div > p
{
  max-width:300px ;
}
.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
   border-left: none !important
}
.loc-container {

  position: absolute;
  top: 0;
  margin: 0 auto;
  width: 100%;
}
.loc-container span{

  width: 100%;
  display: inline-block;
  text-align: center;
  left: 0px;
}

.hero{
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  display: block;
  content: "";
  top: 0!important;
  left: 0!important;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover!important;
  -moz-background-size: cover!important;
  -o-background-size: cover!important;
  background-size: cover!important;
  opacity: 1;
  z-index: -2;
  background-color: #f8dd64!important;
  background-image: url('/assets/bg1.jpg');
  background-position-y: center;
  background-position-x: center;
}
.hero::before{
  background-color: #f8dd64!important;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  opacity: 0.8;
}
.p-slider .p-slider-handle {
  background-color: #efa927!important;
  border-color: #efa927!important;
  border: 2px solid  #c58b21!important;
}
.p-slider .p-slider-range {
  background:bisque!important;
}


.p-slider.p-slider-horizontal {
  width: 50%!important;
  margin: 0 auto!important;
  margin-bottom: 20px!important;
}
body .pi {
  font-size: 2em;
}
mat-toolbar > span:nth-child(1){
  margin-right: 20px;
  width: 100%;
}
 mat-toolbar > span:nth-child(2){
   font-size: 1.4em;
 }
 .mat-mdc-list-base .mat-mdc-subheader {
  height: 48px;
  font-weight: 900;
  color: black;
  background-color: #93939347;
  padding: 5px 0 0 5px;
}
.mdc-list-item__primary-text a{
  text-decoration: none!important;
  color: black!important;
}
.mat-toolbar-row, .mat-toolbar-single-row {
  height: 45px!important;
}


/* button menu */
body > mb-root > mat-sidenav-container > mat-sidenav-content > mat-toolbar > span:nth-child(1) > button{
  background-color: #93939300;
  border: 1px solid #93939300;
}
body > mb-root > mat-sidenav-container > mat-sidenav-content > mat-toolbar > span:nth-child(1) > button:focus{
  background-color: #97959500;
  border-color: #97959500;
  color: bisque;
}
body > mb-root > mat-sidenav-container > mat-sidenav-content > mat-toolbar > span:nth-child(1) > button:hover{
  background-color: #85858500!important;
  border-color: #85858500!important;
}

body > mb-root > mat-sidenav-container > mat-sidenav-content > mat-toolbar > span:nth-child(1) > button:active{
  background-color: #8a878700;
  border-color: #8a878700;
}

.pi-bars:before {
  content: "\e91d";
}
/* 

p-button {
  display: inline-block
}

.ui-button .ui-button-text {
  display: block;
  line-height: normal
}

.ui-button-text-only .ui-button-text {
  padding: .25em 1em
}

.ui-button-icon-only .ui-button-text,.ui-button-text-empty .ui-button-text {
  padding: .25em;
  text-indent: -9999999px;
}

.ui-button-text-icon-left .ui-button-text {
  padding: .25em 1em .25em 2.1em
}

.ui-button-text-icon-right .ui-button-text {
  padding: .25em 2.1em .25em 1em
}

.ui-button-icon-only .ui-button-icon-left,.ui-button-text-icon-left .ui-button-icon-left,.ui-button-text-icon-right .ui-button-icon-right {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  height: 1em
}

.ui-button-icon-only .ui-button-icon-left {
  top: 50%;
  left: 50%;
  margin-top: -.5em;
  margin-left: -.5em;
  width: 1em;
  height: 1em
}

.ui-button-icon-left {
  left: .5em
}

.ui-button-icon-right {
  right: .5em
}

.ui-buttonset .ui-button {
  margin-left: 0;
  margin-right: 0
}

button.ui-button::-moz-focus-inner {
  border: 0;
  padding: 0
} */

.pi {
  font-family: primeicons;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.pi-bars:before {
  content: "\e91d"
}

/* CARD*/
mat-sidenav-content>div p-card:nth-child(1) {
  display: block;
}
body>mb-root>mat-sidenav-container>mat-sidenav-content>div>*>p-card {
  padding-top: 5px;
  margin: 25px;
}
body .ui-card {
  margin: 20px;
  margin-top: 45px;
}

body .ui-card {
  background-color: #fff;
  color: #333;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
}

body .ui-card {
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
}
.title {
  display: block;
  font-size: 1.17em;
  font-weight: 700;
  background: #fc0;
  padding: 1em 0 .5em 1em;
}

.ui-card-body {
  padding: 1em;
}

body .ui-button {
  margin: 0;
  color: #fff;
  background-color: #673ab7;
  border: 1px solid #673ab7;
  font-size: 14px;
  -webkit-transition: background-color .2s,box-shadow .2s;
  transition: background-color .2s,box-shadow .2s
}
mat-toolbar body .ui-button {
  margin: 0;
  color: #000;
  font-size: 14px;
  -webkit-transition: color .2s,box-shadow .2s;
  transition:color .2s,box-shadow .2s
}

body .ui-button:enabled:hover {
  background-color: #6a41af;
  color: #fff;
  border-color: #6a41af
}

body .ui-button:enabled:focus {
  outline: 0;
  outline-offset: 0;
  /* box-shadow: 0 0 0 .2em #6c4da3 */
}

body .ui-button:enabled:active {
  background-color: #5d29b6;
  color: #fff;
  border-color: #5d29b6
}
.pi-download:before {
  content: "\e956";
}
.pi-refresh:before {
  content: "\e938";
}
.pi-times:before {
  content: "\e90b";
}
.ui-toast .ui-toast-message-content {
  padding: .5em 1em;
  background-color: bisque!important;
}

.unselectable {
  -moz-user-select: none;
  webkit-user-select: none;
  ms-user-select: none;
}
[data-pseudo-content]::before,
[data-pseudo-content--before]::before,
[data-pseudo-content--after]::after {
  content: attr(data-pseudo-content);
}
#update > div > p-toastitem > div > div > div.install-button-container > div:nth-child(2),
#update > div > p-toastitem > div > div > div.install-button-container > div:nth-child(2) > button,
p-toastitem > div > div > div.ui-g.ng-star-inserted > div:nth-child(2) > button {
  color: #fff;
  background-color: #34A835;
  border: 1px solid #34A835;
}
.install-button{

  width: 20%;
  margin: 0 auto;
}
.install-button-container{
  width: 100%;
  display: flex;
}
#update .p-toast,#install .p-toast {
  position: fixed;
  width: 19rem;
  background-color: #f1c185;
  box-shadow: 4px 6px 2px #00000029;
}
#update .p-toast-message-content,#install .p-toast-message-content {
  padding: 1rem;
  border-width: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
}

#update .p-toast-icon-close ,#install .p-toast-icon-close{
  color: #212121;
  position: absolute;
  margin-left: 81%;
}